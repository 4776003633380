<template>
  <div>
    <b-card
      :id="'inventoryTaskListBlock' + taskBlock"
      :ref="'inventoryTaskListBlock' + taskBlock"
      no-body
    >
      <div
        v-for="(ir, indx) in inventoryRequests"
        :key="indx"
        class="mb-2 ir-disabled"
      >
        <div class="d-flex justify-content-between">
          <div>
            <span style="font-weight: 500; font-size: 15px; color: #000000;">
              Status:
            </span>
            <b-badge
              :variant="`light-${resolveStatusVariant(ir.status)}`"
              class="ml-50 text-capitalize"
            >
              {{ ir.status ? ir.status : '-' }}
            </b-badge>
            <b-button
              v-if="canViewThisAction('show', 'InventoryRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="ml-50 mb-1 pl-0 mt-75"
              :to="{ name: 'inventory-inventory-request-view', params: { id: ir._id } }"
              style="pointer-events: initial !important;"
            >
              ({{ ir.stringID }})
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              type="button"
              class="ml-50 mb-1 pl-0 mt-75 not-button"
            >
              ({{ ir.stringID }})
            </b-button>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              type="button"
              :disabled="ir.status != 'open'"
              class="ml-auto mb-1 px-0 align-middle mt-75"
              @click="cancelRequest(ir._id, indx)"
            >
              Cancel Request
            </b-button>
            <b-button
              v-if="ir.status == 'cancelled'"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              type="button"
              class="ml-50 mb-1 px-0 align-middle mt-75"
              @click="showHistoryModal()"
            >
              History
            </b-button>
          </div>
        </div>
        <b-card
          no-body
          class="shadow-body"
        >
          <b-card-body>
            <b-row
              v-if="!isMobile()"
              style="margin-bottom: 6px"
            >
              <b-col
                lg="5"
                md="5"
                sm="12"
                class="mt-auto"
              >Item(s)
              </b-col>
              <b-col
                lg="3"
                md="3"
                sm="12"
                class="mt-auto"
              >Store</b-col>
              <b-col
                lg="2"
                md="2"
                sm="12"
                class="px-25"
              >
                <div v-if="fieldValues[3] && fieldValues[3].value == 2">
                  <b-row>
                    <b-col
                      lg="6"
                      md="6"
                      sm="12"
                      class="pr-0"
                    >
                      <span style="font-size: 12px; color: #999999;">
                        NMG
                      </span>
                      <br>
                      <span>
                        Quantity
                      </span>
                    </b-col>
                    <b-col
                      lg="6"
                      md="6"
                      sm="12"
                      class="pl-25"
                    >
                      <span style="font-size: 12px; color: #999999;">
                        MPL
                      </span>
                      <br>
                      <span>
                        Quantity
                      </span>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <span>
                    Quantity
                  </span>
                </div>
              </b-col>
              <b-col
                lg="2"
                md="2"
                sm="12"
                class="mt-auto"
              >
                Item File No.
                <feather-icon
                  :id="'fileNoTooltip' + indx"
                  icon="AlertCircleIcon"
                  class="ml-50 text-primary"
                  size="14"
                />
                <b-tooltip
                  placement="auto"
                  :target="'fileNoTooltip' + indx"
                  title="This is an optional field to add only one (1) urn file no."
                />
              </b-col>
            </b-row>
            <div v-if="inventoryRequestTns.filter(o => o.request == ir._id).length">
              <b-row
                v-for="(stockItem, index) in inventoryRequestTns.filter(o => o.request == ir._id)"
                :id="'stockItems' + index"
                :key="index"
                class="mb-50"
              >
                <b-col
                  lg="5"
                  md="5"
                  sm="12"
                >
                  <span v-if="isMobile()">Item(s)</span>
                  <div
                    class="d-flex justify-content-between search-bar"
                  >
                    <b-form-input
                      :value="stockItem.item.name"
                      name="name"
                      disabled
                    />
                    <b-img
                      class="item-img-block"
                      :src="stockItem.item.image ? stockItem.item.image : defaultImage"
                    />
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  md="3"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Store
                  </span>
                  <b-form-input
                    :value="stockItem.store.name"
                    name="name"
                    disabled
                  />
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                  class="px-25"
                >
                  <div v-if="fieldValues[3] && fieldValues[3].value == 2">
                    <b-row>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pr-0"
                      >
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                          style="font-size: 12px; color: #999999;"
                        >
                          NMG
                        </span>
                        <br v-if="isMobile()">
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                        >
                          Quantity
                        </span>
                        <b-form-input
                          :value="stockItem.quantity[0]"
                          name="quantity"
                          disabled
                        />
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pl-25"
                      >
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                          style="font-size: 12px; color: #999999;"
                        >
                          MPL
                        </span>
                        <br v-if="isMobile()">
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                        >
                          Quantity
                        </span>
                        <b-form-input
                          :value="stockItem.quantity[1]"
                          name="quantity"
                          disabled
                        />
                      </b-col>
                    </b-row>
                  </div>
                  <div v-else>
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Quantity
                    </span>
                    <b-form-input
                      :value="stockItem.quantity[0]"
                      name="quantity"
                      disabled
                    />
                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Item File No.
                  </span>
                  <b-form-input
                    :value="stockItem.fileNo"
                    name="fileNo"
                    disabled
                  />
                </b-col>
              </b-row>

              <div class="d-flex justify-content-between mt-2">
                <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ ir.totalItems }}</p>
                <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75">{{ ir.totalQuantity }}</span></p>
              </div>
              <hr>
              <b-form-group
                label="Add Remarks"
                label-for="remarks"
                label-cols="2"
              >
                <b-form-textarea
                  id="remarks"
                  :value="ir.remarks"
                  name="remarks"
                  rows="5"
                  disabled
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mt-2"
                type="button"
                disabled
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-25"
                />
                <span class="align-middle">Inventory Request Generated</span>
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div v-if="!createNew">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mb-1"
          type="button"
          @click="createNew = true"
        >
          <span class="align-middle">Add New Inventory Request</span>
        </b-button>
      </div>
      <b-card
        v-if="createNew"
        no-body
        class="shadow-body"
      >
        <b-card-body>
          <b-form @submit.prevent="showPreviewModal">
            <validation-observer
              ref="irForm"
              #default="{}"
            >
              <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
              <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
              <b-row
                v-if="!isMobile()"
                style="margin-bottom: 6px"
              >
                <b-col
                  lg="4"
                  md="4"
                  sm="12"
                  class="mt-auto"
                >Item(s)
                </b-col>
                <b-col
                  lg="3"
                  md="3"
                  sm="12"
                  class="mt-auto"
                >Store</b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                  class="px-25"
                >
                  <div v-if="fieldValues[3] && fieldValues[3].value == 2">
                    <b-row>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pr-0"
                      >
                        <span style="font-size: 12px; color: #999999;">
                          NMG
                        </span>
                        <br>
                        <span>
                          Quantity
                        </span>
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pl-25"
                      >
                        <span style="font-size: 12px; color: #999999;">
                          MPL
                        </span>
                        <br>
                        <span>
                          Quantity
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-else>
                    <span>
                      Quantity
                    </span>
                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                  class="mt-auto"
                >
                  Item File No.
                  <feather-icon
                    id="fileNoTooltip"
                    icon="AlertCircleIcon"
                    class="ml-50 text-primary"
                    size="14"
                  />
                  <b-tooltip
                    placement="auto"
                    target="fileNoTooltip"
                    title="This is an optional field to add only one (1) urn file no."
                  />
                </b-col>
                <b-col
                  lg="1"
                  md="1"
                  sm="12"
                  class="mt-auto"
                />
              </b-row>
              <div v-if="stockItems.length">
                <b-row
                  v-for="(stockItem, index) in stockItems"
                  :id="'stockItems' + index"
                  :key="index"
                >
                  <b-col
                    lg="4"
                    md="4"
                    sm="12"
                  >
                    <span v-if="isMobile()">Item(s)</span>
                    <div
                      class="d-flex justify-content-between search-bar"
                    >
                      <!-- <validation-provider
                        #default="{ errors }"
                        name="Name"
                        vid="name"
                        rules="required"
                        style="width: 100%"
                      >
                        <b-form-group
                          :label-for="'itemName'+ index"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <b-form-input
                            :id="'itemName'+ index"
                            v-model="stockItem.name"
                            name="name"
                            :state="(errors.length > 0) ? false : null"
                            placeholder="Item name"
                            disabled
                            class="disabled-clear"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider> -->
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        :vid="`name_${index}`"
                        rules="required"
                        style="width: 100%"
                      >
                        <b-form-group
                          :label-for="`name_${index}`"
                          class="mb-0"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <v-select
                            :id="`name_${index}`"
                            v-model="stockItem.item"
                            label="name"
                            placeholder="Select an item"
                            class="disabled-clear"
                            :options="inventoryItems"
                            :reduce="name => name._id"
                            :clearable="false"
                            @input="updateStockItem(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <b-img
                        class="item-img-block"
                        :src="stockItem.image ? stockItem.image : defaultImage"
                      />
                    </div>
                  </b-col>
                  <b-col
                    lg="3"
                    md="3"
                    sm="12"
                  >
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Store
                    </span>
                    <!-- <v-select
                  v-model="store"
                  label="name"
                  placeholder="Select a store"
                  :options="storeDataOptions"
                  :reduce="name => name._id"
                  :clearable="false"
                >
                  <template #option="{ name, department }">
                    <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                    <br>
                    <span
                      style="font-weight: 500;font-size: 12px;"
                      class="text-muted"
                    >
                      {{ department.name }}
                    </span>
                  </template>
                </v-select> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Store"
                      :vid="`store_${index}`"
                      rules="required"
                    >
                      <b-form-group
                        :label-for="`store_${index}`"
                        class="mb-0"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <v-select
                          :id="`store_${index}`"
                          v-model="stockItem.store"
                          label="name"
                          placeholder="Select a store"
                          class="disabled-clear"
                          :options="storeDataOptionComputation(stockItem.item)"
                          :reduce="name => name._id"
                          :clearable="false"
                          @input="stockQuantityValidation(stockItem.item, stockItem.store)"
                        >
                          <template #option="{ name, department }">
                            <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                            <br>
                            <span
                              style="font-weight: 500;font-size: 12px;"
                              class="text-muted"
                            >
                              {{ department.name }}
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    lg="2"
                    md="2"
                    sm="12"
                    class="px-25"
                  >
                    <div v-if="fieldValues[3] && fieldValues[3].value == 2">
                      <b-row>
                        <b-col
                          lg="6"
                          md="6"
                          sm="12"
                          class="pr-0"
                        >
                          <span
                            v-if="isMobile()"
                            class="mt-50 d-block"
                            style="font-size: 12px; color: #999999;"
                          >
                            NMG
                          </span>
                          <br v-if="isMobile()">
                          <span
                            v-if="isMobile()"
                            class="mt-50 d-block"
                          >
                            Quantity
                          </span>
                          <!-- <b-form-input
                        id="search_quantity"
                        v-model="quantity[0]"
                        name="quantity"
                        placeholder="0"
                        @keypress="validateNumberInput"
                        @paste="validateNumberPaste"
                      /> -->
                          <validation-provider
                            #default="{ errors }"
                            name="Quantity"
                            :vid="'quantity1'+ index"
                            :rules="!stockItem.quantity[1] ? 'required' : ''"
                            style="width: 100%"
                          >
                            <b-form-group
                              :label-for="'quantity1'+ index"
                              :class="{ 'is-invalid': errors.length }"
                            >
                              <b-form-input
                                :id="'quantity1'+ index"
                                v-model="stockItem.quantity[0]"
                                name="quantity"
                                :state="(errors.length > 0) ? false : null"
                                placeholder="0"
                                @keypress="validateNumberInput"
                                @paste="validateNumberPaste"
                                @input="stockQuantityValidation(stockItem.item, stockItem.store)"
                              />
                              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          lg="6"
                          md="6"
                          sm="12"
                          class="pl-25"
                        >
                          <span
                            v-if="isMobile()"
                            class="mt-50 d-block"
                            style="font-size: 12px; color: #999999;"
                          >
                            MPL
                          </span>
                          <br v-if="isMobile()">
                          <span
                            v-if="isMobile()"
                            class="mt-50 d-block"
                          >
                            Quantity
                          </span>
                          <validation-provider
                            #default="{ errors }"
                            name="Quantity"
                            :vid="'quantity2'+ index"
                            :rules="!stockItem.quantity[0] ? 'required' : ''"
                            style="width: 100%"
                          >
                            <b-form-group
                              :label-for="'quantity2'+ index"
                              :class="{ 'is-invalid': errors.length }"
                            >
                              <b-form-input
                                :id="'quantity2'+ index"
                                v-model="stockItem.quantity[1]"
                                name="quantity"
                                :state="(errors.length > 0) ? false : null"
                                placeholder="0"
                                @keypress="validateNumberInput"
                                @paste="validateNumberPaste"
                                @input="stockQuantityValidation(stockItem.item, stockItem.store)"
                              />
                              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          v-if="stockItem.quantityError"
                          lg="12"
                          md="12"
                          sm="12"
                          class=""
                          style="margin-top: -10px;"
                        >
                          <small
                            class="text-danger"
                          >
                            {{ stockItem.quantityErrorMessage }}
                          </small>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-else>
                      <span
                        v-if="isMobile()"
                        class="mt-50 d-block"
                      >
                        Quantity
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="Quantity"
                        :vid="'quantity1'+ index"
                        rules="required"
                        style="width: 100%"
                      >
                        <b-form-group
                          :label-for="'quantity1'+ index"
                          :class="{ 'is-invalid': errors.length }"
                        >
                          <b-form-input
                            :id="'quantity1'+ index"
                            v-model="stockItem.quantity[0]"
                            name="quantity"
                            :state="(errors.length > 0) ? false : null"
                            placeholder="0"
                            @keypress="validateNumberInput"
                            @paste="validateNumberPaste"
                            @input="stockQuantityValidation(stockItem.item, stockItem.store)"
                          />
                          <!-- <b-form-spinbutton
                            v-model="stockItem.quantity[0]"
                          >
                            <template #increment>
                              <feather-icon icon="ChevronUpIcon" />
                            </template>
                            <template #decrement>
                              <feather-icon icon="ChevronDownIcon" />
                            </template>
                          </b-form-spinbutton> -->
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                          <small
                            v-if="stockItem.quantityError"
                            class="text-danger"
                          >
                            {{ stockItem.quantityErrorMessage }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    lg="2"
                    md="2"
                    sm="12"
                  >
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Item File No.
                    </span>
                    <validation-provider
                      #default="{ errors }"
                      name="File No"
                      :vid="'fileNo'+ index"
                      rules="max:15"
                      style="width: 100%"
                    >
                      <b-form-group
                        :label-for="'fileNo'+ index"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-form-input
                          :id="'fileNo'+ index"
                          v-model="stockItem.fileNo"
                          name="fileNo"
                          :state="(errors.length > 0) ? false : null"
                          placeholder="Eg. 68A013401"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="1"
                    lg="1"
                    sm="12"
                    :class="{'p-0':!isMobile()}"
                  >
                    <b-button
                      variant="flat-primary"
                      class="pl-0 primary-button-with-background no-background"
                      type="button"
                      style="margin: 0 !important;"
                      @click="removeStockItem(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-25 trash-icon"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <span v-if="isMobile()">Item(s)</span>
                  <div
                    class="d-flex justify-content-between search-bar"
                  >
                    <b-form-input
                      id="search_name"
                      v-model="searchInput"
                      label="Item(s)"
                      name="name"
                      placeholder="Search item"
                      autocomplete="off"
                      @keyup="submitSearchInput()"
                    />
                    <b-img
                      class="item-img-block"
                      :src="defaultImage"
                    />
                  </div>
                  <div v-show="searchInputResult">
                    <div
                      class="search-results mt-50"
                    >
                      <div>
                        <b-list-group
                          v-if="storeItemSearchInputData.length"
                          flush
                        >
                          <span class="found-text-block">Inventory items found in this store</span>
                          <b-list-group-item
                            v-for="(storeItem, sIndex) in storeItemSearchInputData"
                            :key="sIndex"
                            class="cursor-pointer"
                            @click="addSearchInputStoreItem(sIndex, storeItem.item._id)"
                          >
                            <div class="d-flex">
                              <b-img
                                class="item-img-block"
                                :src="storeItem.item.image ? storeItem.item.image : defaultImage"
                              />
                              <div
                                class="search-item-text-block"
                              >
                                <h6>{{ storeItem.item.name }}</h6>
                                <small class="text-muted">{{ storeItem.item.sku }}</small>
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                        <b-list-group
                          v-if="masterItemSearchInputData.length"
                          flush
                          class="mt-1"
                        >
                          <span class="found-text-block">Inventory items found in Master list</span>
                          <b-list-group-item
                            v-for="(masterItem, mIndex) in masterItemSearchInputData"
                            :key="mIndex"
                            class="cursor-pointer"
                            @click="addSearchInputMasterItem(mIndex, masterItem._id)"
                          >
                            <div class="d-flex">
                              <b-img
                                class="item-img-block"
                                :src="masterItem.image ? masterItem.image : defaultImage"
                              />
                              <div
                                class="search-item-text-block"
                              >
                                <h6>{{ masterItem.name }}</h6>
                                <small class="text-muted">{{ masterItem.sku }}</small>
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>
                  </div>
                  <b-card
                    v-show="searchResultNotFound"
                    no-body
                    class="mt-50"
                  >
                    <div class="result-not-found-block">
                      <h5>No inventory item found.</h5>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  lg="3"
                  md="3"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Store
                  </span>
                  <v-select
                    id="search_store"
                    v-model="store"
                    label="name"
                    placeholder="Select a store"
                    :options="storeDataOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="submitSearchInput()"
                  >
                    <template #option="{ name, department }">
                      <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                      <br>
                      <span
                        style="font-weight: 500;font-size: 12px;"
                        class="text-muted"
                      >
                        {{ department.name }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                  class="px-25"
                >
                  <div v-if="fieldValues[3] && fieldValues[3].value == 2">
                    <b-row>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pr-0"
                      >
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                          style="font-size: 12px; color: #999999;"
                        >
                          NMG
                        </span>
                        <br v-if="isMobile()">
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                        >
                          Quantity
                        </span>
                        <b-form-input
                          id="search_quantity"
                          v-model="quantity[0]"
                          name="quantity"
                          placeholder="0"
                          @keypress="validateNumberInput"
                          @paste="validateNumberPaste"
                        />
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        sm="12"
                        class="pl-25"
                      >
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                          style="font-size: 12px; color: #999999;"
                        >
                          MPL
                        </span>
                        <br v-if="isMobile()">
                        <span
                          v-if="isMobile()"
                          class="mt-50 d-block"
                        >
                          Quantity
                        </span>
                        <b-form-input
                          id="search_quantity"
                          v-model="quantity[1]"
                          name="quantity"
                          placeholder="0"
                          @keypress="validateNumberInput"
                          @paste="validateNumberPaste"
                        />
                      </b-col>
                    </b-row>
                  </div>
                  <div v-else>
                    <span
                      v-if="isMobile()"
                      class="mt-50 d-block"
                    >
                      Quantity
                    </span>
                    <b-form-input
                      id="search_quantity"
                      v-model="quantity[0]"
                      name="quantity"
                      placeholder="0"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  sm="12"
                >
                  <span
                    v-if="isMobile()"
                    class="mt-50 d-block"
                  >
                    Item File No.
                  </span>
                  <b-form-input
                    id="search_fileNO"
                    v-model="placeholderFileNo"
                    name="fileNo"
                    placeholder="Eg. 68A013401"
                  />
                </b-col>
                <b-col
                  lg="1"
                  md="1"
                  sm="0"
                />
              </b-row>
              <div class="d-flex justify-content-between mt-2">
                <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ requestItemsQuantity }}</p>
                <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75">{{ requestItemsTotalQuantity }}</span></p>
              </div>
              <hr>
              <b-form-group
                label="Add Remarks"
                label-for="remarks"
                label-cols="2"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  name="remarks"
                  rows="5"
                  placeholder="Optional"
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2"
                type="submit"
              >
                Generate Inventory Request
              </b-button>
            </validation-observer>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card>
    <b-modal
      :id="'modal-request-items' + uniqueComponentID"
      ref="request-items-modals"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitForm"
      @cancel="closeRequestPreviewModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="PackageIcon"
            style="color: #7367F0; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Proceed with Inventory Request?
            </h4>
            <p style="color: #333333;">
              You have requested for the following inventory item(s).
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="fieldValues[3] && fieldValues[3].value == 1">
          <b-tr
            v-for="(stockInItem, stockIndex) in stockItems"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.image ? stockInItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div></b-td>
            <b-td>
              {{ resolveStoreName(stockInItem.store) }}
            </b-td>
            <b-td>
              <span
                class=""
              >{{ stockInItem.quantity.reduce((a, b) => a - (-b), 0) }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-if="fieldValues[3] && fieldValues[3].value == 2">
          <b-tr
            v-for="(stockInItem, stockIndex) in splitStockItems()"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.image ? stockInItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div></b-td>
            <b-td>
              <span>{{ resolveStoreName(stockInItem.store) }}</span>
              <br>
              <span>{{ stockInItem.department }}</span>
            </b-td>
            <b-td>
              <span
                class=""
              >{{ stockInItem.quantity }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ requestItemsQuantity }}</p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75">{{ requestItemsTotalQuantity }}</span></p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      :id="'history-modal' + uniqueComponentID"
      ref="history-modals"
      title="History"
      modal-class="status-update-modal"
      body-class="no-padding-body"
      size="md"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
    >
      <b-card-body class="px-0 pt-0">
        <div
          v-for="(req, indx) in inventoryRequests.filter(o => o.status == 'cancelled')"
          :key="indx"
        >
          <hr>
          <b-media
            class="px-1"
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="req.updatedBy.image"
                :text="resolveAcronymName(req.updatedBy.name)"
              />
            </template>
            <p class="media-heading mb-0">
              <span style="font-weight: 500;">
                Inventory Request <b-button
                  v-if="canViewThisAction('show', 'InventoryRequest')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="link"
                  class="p-0 mt-0"
                  :to="{ name: 'inventory-inventory-request-view', params: { id: req._id } }"
                >
                  {{ req.stringID }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  type="button"
                  class="p-0 mt-0"
                >
                  {{ req.stringID }}
                </b-button> was cancelled by {{ req.updatedBy.name }}.
              </span>
            </p>
            <span class="text-muted">{{ dateFormatWithTime(req.updatedAt) }}</span>
          </b-media>
        </div>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BForm, BButton, BFormTextarea, BFormGroup, BFormInput, BRow, BCol, BImg, VBTooltip, BTooltip, BListGroup,
  BListGroupItem, BBadge, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardBody,
    BForm,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BImg,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BMedia,
    BAvatar,
    // BFormSpinbutton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    submitDate: {
      type: String,
      default: null,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
    serviceRequestStringId: {
      type: String,
      default: null,
    },
    serviceDate: {
      type: String,
      default: null,
    },
    fileNo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      saveStockOut: false,
      createNew: false,
      searchInput: '',
      storeOriginal: '',
      store: '',
      sectionId: '',
      user: store.state.auth.userData,
      quantity: [],
      // fileNo: '',
      remarks: '',
      remarksError: 'Valid Remarks is required',
      remarksValidation: false,
      storeDataOptions: [],
      stockItems: [],
      storeItemData: [],
      selectStockItem: [],
      storeItemSearchInputData: [],
      searchInputResult: false,
      searchResultNotFound: false,
      stockOutPreviewItems: [],
      inventoryRequests: [],
      inventoryRequestTns: [],
      openInventoryRequestTns: [],
      inventoryItems: [],
      masterItemSearchInputData: [],
      hasError: false,
      placeholderFileNo: this.fileNo,
      uniqueComponentID: uuidv4(),

      // validation rules
      required,
      max,
    }
  },
  computed: {
    requestItemsTotalQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.quantity.length) {
            // eslint-disable-next-line eqeqeq
            if (this.fieldValues[3] && this.fieldValues[3].value == 2) {
              initStockQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
            } else {
              initStockQuantity -= -item.quantity[0]
            }
          }
        })
        return initStockQuantity
      }
      return 0
    },
    requestItemsQuantity() {
      const allStockItems = this.stockItems.length
      if (allStockItems) {
        return this.stockItems.map(item => item.item).filter((value, index, self) => self.indexOf(value) === index).length
      }
      return '-'
    },
  },
  async created() {
    // if (this.submitDate) {
    //   this.createNew = false
    // } else {
    //   this.createNew = true
    // }
    // const formVal = this.fieldValues.find(o => o.key === 'Forms')
    // if (formVal && formVal.value && !Array.isArray(formVal.value)) {
    //   document.getElementById('inventoryTaskListBlock').scrollIntoView()
    // }
    // await this.$nextTick()
    // if (formVal && formVal.value && !Array.isArray(formVal.value)) {
    //   const { section } = this.$route.query
    //   if (section) {
    //     const element = this.$refs[section + this.taskBlock]
    //     if (element) {
    //       element.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' })
    //     }
    //   }
    // }
    this.getInventoryRequests(this.inputValues, true)
    // const formData = new FormData()
    // formData.append('ir', JSON.stringify(this.inputValues))
    // // this.$http.get('inventory/stock/respond-with/store-and-item')
    // this.$http.post('inventory/inventory-request/respond-with/store-and-item-and-ir', formData, {
    //   headers: { 'Content-Type': 'multipart/form-data' },
    // })
    //   .then(response => {
    //     this.storeDataOptions = response.data.stores
    //     this.storeItemData = response.data.storeItems
    //     this.inventoryRequests = response.data.inventoryRequests
    //     this.inventoryRequestTns = response.data.inventoryRequestTns
    //     this.store = this.storeDataOptions[0]._id
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  },
  methods: {
    canViewThisAction,
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'cancelled') return 'danger'
      if (status === 'returned') return 'warning'
      if (status === 'collected') return 'secondary'
      if (status === 'open') return 'info'
      return 'primary'
    },
    getInventoryRequests(ids, updateEvent = false) {
      const formData = new FormData()
      formData.append('ir', JSON.stringify(ids))
      // this.$http.get('inventory/stock/respond-with/store-and-item')
      this.$http.post('inventory/inventory-request/respond-with/store-and-item-and-ir', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          this.storeDataOptions = response.data.stores
          this.storeItemData = response.data.storeItems
          this.inventoryItems = response.data.items
          this.inventoryRequests = response.data.inventoryRequests
          this.inventoryRequestTns = response.data.inventoryRequestTns
          this.openInventoryRequestTns = response.data.openInventoryRequestTns
          // this.store = this.storeDataOptions[0]._id
          if (!this.inventoryRequests.length) {
            this.createNew = true
          }

          if (updateEvent) {
            await this.$nextTick()
            this.$emit('updateCount')
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showHistoryModal() {
      this.$root.$emit('bv::show::modal', `history-modal${this.uniqueComponentID}`, '')
    },
    cancelRequest(id, indx) {
      this.$swal({
        title: 'Cancel this request?',
        html: 'Cancellation is final, but you can submit a new inventory request later.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'cancelled')
            this.$http.patch(`inventory/inventory-request/${id}/status/update`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.inventoryRequests[indx].status = 'cancelled'
                this.$swal({
                  title: 'Request Cancelled!',
                  html: 'The status for this request has been updated to cancelled',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                if (error.data.errors) {
                  error.data.errors.forEach(validationError => {
                    if (validationError.param === 'status') {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: validationError.msg ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
          }
        })
    },
    submitForm() {
      this.$refs.irForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const itemsArray = []
          const storesArray = []
          // let storeList = ''
          this.stockItems.forEach(item => {
            if (!itemsArray.includes(item.item)) {
              itemsArray.push(item.item)
            }
            const exists = storesArray.find(obj => obj.store === item.store)
            if (exists) {
              const itemExists = exists.items.findIndex(obj => obj.item === item.item)
              if (itemExists !== -1) {
                exists.totalQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
                exists.items[itemExists].quantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
              } else {
                const itemArray = {
                  item: item.item,
                  quantity: +item.quantity.reduce((a, b) => a - (-b), 0),
                }
                exists.totalItems += 1
                exists.totalQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
                exists.items.push(itemArray)
              }
            } else {
              const itemArray = {
                item: item.item,
                quantity: +item.quantity.reduce((a, b) => a - (-b), 0),
              }
              const storeArr = {
                store: item.store,
                items: [itemArray],
                totalItems: 1,
                totalQuantity: +item.quantity.reduce((a, b) => a - (-b), 0),
              }

              storesArray.push(storeArr)
            }
          })
          // formData.append('items', JSON.stringify(itemsArray))
          // formData.append('stores', JSON.stringify(storesArray))
          const uniqueStores = this.stockItems.map(item => item.store)
            .filter((value, index, self) => self.indexOf(value) === index)
          formData.append('items', JSON.stringify(this.stockItems))
          formData.append('itemsArray', JSON.stringify(itemsArray))
          formData.append('stores', JSON.stringify(uniqueStores))
          formData.append('totalItems', this.requestItemsQuantity)
          formData.append('totalQuantity', this.requestItemsTotalQuantity)
          formData.append('remarks', this.remarks)
          formData.append('serviceRequest', this.serviceRequestId)
          formData.append('serviceRequestStringID', this.serviceRequestStringId)
          formData.append('serviceForm', this.serviceForm._id)
          formData.append('serviceFormStringID', this.serviceForm.stringID)
          formData.append('serviceDate', this.serviceDate)
          formData.append('customerName', this.serviceForm.customerName)
          formData.append('fileNo', this.fileNo)
          formData.append('status', this.fieldValues[4].value)
          formData.append('splitQuantity', this.fieldValues[3].value)
          formData.append('block', this.taskBlock)
          formData.append('storewiseItems', JSON.stringify(storesArray))
          this.$http.post('inventory/inventory-request/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              const slot = response.data.serviceRequestTasks.taskBlocks.find(o => o._id === this.taskBlock)
              // this.inventoryRequests = response.data.inventoryRequests
              // this.inventoryRequestTns = response.data.inventoryRequestTns
              this.$emit('update:inputValues', slot.inputValues)
              this.$emit('update:submitDate', slot.submitDate)
              this.getInventoryRequests(slot.inputValues)
              // this.files = []
              this.stockItems = []
              this.createNew = false

              this.$swal({
                title: 'Inventory Request Created',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                cancelButtonText: '',
                allowOutsideClick: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary mr-1',
                  cancelButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                let msg = ''
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'items') {
                    msg = validationError.msg
                  } else if (validationError.param === 'totalItems') {
                    msg = validationError.msg
                  } else if (validationError.param === 'totalQuantity') {
                    msg = validationError.msg
                  }
                })

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    storeDataOptionComputation(stockItemId) {
      const a = this.storeDataOptions.filter(obj => {
        const found = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === obj._id)
        if (found) {
          return true
        }
        return false
      })

      return a
    },
    addMoreStore(index) {
      this.stockItems[index].stores.push({
        store: '',
        quantity: '',
      })
    },
    removeStoreData(index, storeIndex) {
      this.stockItems[index].stores.splice(storeIndex, 1)
    },
    removeStockItem(stockIndex) {
      const itemID = this.stockItems[stockIndex].item
      const storeID = this.stockItems[stockIndex].store
      this.stockItems.splice(stockIndex, 1)
      this.stockQuantityValidation(itemID, storeID)
    },
    submitSearchInput() {
      if (this.fieldValues[5] && (this.fieldValues[5].value === 'all' || this.fieldValues[5].value === '')) {
        if (this.store) {
          this.masterItemSearchInputData = []
          this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
        } else {
          this.storeItemSearchInputData = []
          this.masterItemSearchInputData = this.inventoryItems.filter(item => item.name.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase()))
          // this.storeItemSearchInputData = this.storeItemData.filter(item => item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
        }
      } else if (this.fieldValues[5] && (this.fieldValues[5].value !== 'all' || this.fieldValues[5].value !== '')) {
        if (this.store) {
          this.masterItemSearchInputData = []
          this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.category === this.fieldValues[5].value && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
        } else {
          this.storeItemSearchInputData = []
          this.masterItemSearchInputData = this.inventoryItems.filter(item => item.category === this.fieldValues[5].value && (item.name.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
          // this.storeItemSearchInputData = this.storeItemData.filter(item => item.category === this.fieldValues[5].value && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
        }
      }
      // this.storeItemSearchInputData = this.storeItemData.filter(item => item.store === this.store && item.item.deletedAt === null && (item.itemName.toLowerCase().includes(this.searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.searchInput.toLowerCase())))
      if (this.searchInput !== '') {
        this.searchInputResult = true
      } else {
        this.searchInputResult = false
      }
      // if (!this.storeItemSearchInputData.length) {
      //   this.searchResultNotFound = true
      // } else {
      //   this.searchResultNotFound = false
      // }
      if (!this.storeItemSearchInputData.length && !this.masterItemSearchInputData.length) {
        if (this.searchInput === '') {
          this.searchResultNotFound = false
        } else {
          this.searchResultNotFound = true
        }
      } else {
        this.searchResultNotFound = false
      }
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    updateStockItem(index) {
      const item = this.inventoryItems.find(masterItemObj => masterItemObj._id === this.stockItems[index].item)
      this.stockItems[index].category = item.category
      this.stockItems[index].name = item.name
      this.stockItems[index].image = item.image
      this.stockItems[index].sku = item.sku
      this.stockItems[index].store = ''
      this.stockItems[index].quantityError = false
    },
    addSearchInputMasterItem(index, id) {
      if (this.inventoryItems.length) {
        const item = this.inventoryItems.find(masterItemObj => masterItemObj._id === id)
        if (item) {
          if (this.store) {
            const sItem = this.storeItemData.find(storeItemObj => storeItemObj.item._id === id && storeItemObj.store === this.store)
            if (sItem) {
              this.stockItems.push({
                item: item._id,
                category: item.category,
                stock: 0,
                status: '',
                name: item.name,
                image: item.image,
                sku: item.sku,
                store: this.store,
                quantity: JSON.parse(JSON.stringify(this.quantity)),
                // quantity: [],
                quantityError: false,
                quantityErrorMessage: '',
                fileNo: this.placeholderFileNo,
              })
            } else {
              this.stockItems.push({
                item: item._id,
                category: item.category,
                stock: 0,
                status: '',
                name: item.name,
                image: item.image,
                sku: item.sku,
                store: '',
                quantity: JSON.parse(JSON.stringify(this.quantity)),
                // quantity: [],
                quantityError: false,
                quantityErrorMessage: '',
                fileNo: this.placeholderFileNo,
              })
            }
          } else {
            this.stockItems.push({
              item: item._id,
              category: item.category,
              stock: 0,
              status: '',
              name: item.name,
              image: item.image,
              sku: item.sku,
              store: '',
              quantity: JSON.parse(JSON.stringify(this.quantity)),
              // quantity: [],
              quantityError: false,
              quantityErrorMessage: '',
              fileNo: this.placeholderFileNo,
            })
          }
          this.quantity = []
          this.searchInput = ''
          this.searchInputResult = false
          this.masterItemSearchInputData.splice(index, 1)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This item is not found on master list.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    addSearchInputStoreItem(index, itemId) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj.item._id === itemId && storeItemObj.store === this.store)
        if (item) {
          // if (this.stockItems.length) {
          //   const foundItemId = this.stockItems.find(obj => obj.item === item.item._id && obj.store === this.store)
          //   if (foundItemId) {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: 'This item is already added!',
          //         icon: 'AlertTriangleIcon',
          //         variant: 'danger',
          //       },
          //     })
          //   } else {
          //     this.stockItems.push({
          //       item: item.item._id,
          //       category: item.category,
          //       name: item.item.name,
          //       image: item.item.image,
          //       sku: item.item.sku,
          //       store: this.store,
          //       quantity: [],
          //       fileNo: '',
          //       // stores: [{
          //       //   store: this.store,
          //       //   quantity: this.quantity,
          //       // }],
          //     })
          //     this.searchInput = ''
          //     this.searchInputResult = false
          //     this.storeItemSearchInputData.splice(index, 1)
          //   }
          // } else {
          //   this.stockItems.push({
          //     item: item.item._id,
          //     category: item.category,
          //     name: item.item.name,
          //     image: item.item.image,
          //     sku: item.item.sku,
          //     store: this.store,
          //     quantity: [],
          //     fileNo: '',
          //     // stores: [{
          //     //   store: this.store,
          //     //   quantity: this.quantity,
          //     // }],
          //   })
          //   this.searchInput = ''
          //   this.searchInputResult = false
          //   this.storeItemSearchInputData.splice(index, 1)
          // }
          this.stockItems.push({
            item: item.item._id,
            category: item.category,
            stock: item.stock,
            status: item.status,
            name: item.item.name,
            image: item.item.image,
            sku: item.item.sku,
            store: this.store,
            quantity: JSON.parse(JSON.stringify(this.quantity)),
            // quantity: [],
            quantityError: false,
            quantityErrorMessage: '',
            fileNo: this.placeholderFileNo,
            // stores: [{
            //   store: this.store,
            //   quantity: this.quantity,
            // }],
          })
          this.quantity = []
          this.searchInput = ''
          this.searchInputResult = false
          this.storeItemSearchInputData.splice(index, 1)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'This item is not found on this store.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    stockStoreValidation(stockItemIndex, stockItemId, storeId) {
      const storeFound = this.stockItems.filter(sData => sData.item === stockItemId && sData.store === storeId)
      if (storeFound.length > 1) {
        this.stockItems[stockItemIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Inventory item was added for the selected store once before!',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    stockQuantityValidation(stockItemId, storeId, showError = false) {
      if (storeId) {
        const groupData = this.stockItems.filter(obj => obj.item === stockItemId && obj.store === storeId).reduce((rv, x) => {
          const v = x.item
          const s = x.store
          const el = rv.find(r => r && r.item === v && r.store === s)
          if (el) {
            el.values.push(x)
          } else {
            rv.push({ item: v, store: s, values: [x] })
          }
          return rv
        }, [])
        groupData.forEach(item => {
          let initStockQuantity = 0
          let openIRQuantity = 0
          this.openInventoryRequestTns.filter(obj => obj.item === item.item && obj.store === item.store).forEach(i => {
            openIRQuantity -= -i.totalQuantity
          })
          const storeFound = this.storeItemData.find(sData => sData.item._id === item.item && sData.store === item.store)
          item.values.forEach(val => {
            if (val.quantity.length) {
              let thisQty = 0
              // eslint-disable-next-line eqeqeq
              if (this.fieldValues[3] && this.fieldValues[3].value == 2) {
                thisQty -= -val.quantity.reduce((a, b) => a - (-b), 0)
              } else {
                thisQty -= -val.quantity[0]
              }
              // eslint-disable-next-line no-param-reassign
              // val.quantityError = false
              const totalQuantity = initStockQuantity + openIRQuantity + thisQty
              if (totalQuantity > storeFound.stock) {
                // eslint-disable-next-line no-param-reassign
                val.quantityError = true
                // eslint-disable-next-line no-param-reassign
                val.quantityErrorMessage = `Out of stock, ${(storeFound.stock - (initStockQuantity + openIRQuantity))} available`
              } else if (storeFound.minStock && (storeFound.stock - totalQuantity) <= storeFound.minStock) {
                // eslint-disable-next-line no-param-reassign
                val.quantityError = true
                // eslint-disable-next-line no-param-reassign
                val.quantityErrorMessage = `Low stock, ${storeFound.stock - totalQuantity} left`
                initStockQuantity -= -thisQty
              } else {
                // eslint-disable-next-line no-param-reassign
                val.quantityError = false
                initStockQuantity -= -thisQty
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (showError) {
                // eslint-disable-next-line no-param-reassign
                val.quantityError = true
                // eslint-disable-next-line no-param-reassign
                val.quantityErrorMessage = 'The quantity field is required'
              }
            }
          })
        })
      }
    },
    // stockQuantityValidation(stockItemIndex, stockItemId, storeId) {
    //   const storeFound = this.storeItemData.find(sData => sData.item._id === stockItemId && sData.store === storeId)
    //   if (storeFound) {
    //     let initStockQuantity = 0
    //     this.stockItems.filter(obj => obj.item === stockItemId && obj.store === storeId).forEach((item, idx) => {
    //       if (idx <= stockItemIndex && item.quantity.length) {
    //         // eslint-disable-next-line eqeqeq
    //         if (this.fieldValues[3] && this.fieldValues[3].value == 2) {
    //           initStockQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
    //         } else {
    //           initStockQuantity -= -item.quantity[0]
    //         }
    //         // eslint-disable-next-line no-param-reassign
    //         item.quantityError = false
    //       }
    //     })

    //     this.openInventoryRequestTns.filter(obj => obj.item === stockItemId && obj.store === storeId).forEach(item => {
    //       if (item.quantity.length) {
    //         // eslint-disable-next-line eqeqeq
    //         if (item.request && item.request.splitQuantity == 2) {
    //           initStockQuantity -= -item.quantity.reduce((a, b) => a - (-b), 0)
    //         } else {
    //           initStockQuantity -= -item.quantity[0]
    //         }
    //       }
    //     })

    //     if (initStockQuantity > storeFound.stock) {
    //       // this.stockItems[stockItemIndex].quantity[quantityIndex] = ''
    //       // this.hasError = true
    //       this.stockItems[stockItemIndex].quantityError = true
    //       this.stockItems[stockItemIndex].quantityErrorMessage = 'Out of stock'
    //       // this.$toast({
    //       //   component: ToastificationContent,
    //       //   props: {
    //       //     title: `Stock not available more than ${storeFound.stock} in this item!`,
    //       //     icon: 'AlertTriangleIcon',
    //       //     variant: 'warning',
    //       //   },
    //       // })
    //     } else if (storeFound.minStock && (storeFound.stock - initStockQuantity) <= storeFound.minStock) {
    //       // this.hasError = false
    //       this.stockItems[stockItemIndex].quantityError = true
    //       this.stockItems[stockItemIndex].quantityErrorMessage = `Low stock, ${storeFound.stock - initStockQuantity} left`
    //     } else {
    //       // this.hasError = false
    //       this.stockItems[stockItemIndex].quantityError = false
    //     }
    //   }
    // },
    catchQuantityError() {
      const groupData = this.stockItems.reduce((rv, x) => {
        const v = x.item
        const s = x.store
        const el = rv.find(r => r && r.item === v && r.store === s)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({ item: v, store: s, values: [x] })
        }
        return rv
      }, [])
      let errorFound = 0
      groupData.forEach(item => {
        let initStockQuantity = 0
        const storeFound = this.storeItemData.find(sData => sData.item._id === item.item && sData.store === item.store)
        if (storeFound) {
          item.values.forEach(val => {
            if (val.quantity.length) {
            // eslint-disable-next-line eqeqeq
              if (this.fieldValues[3] && this.fieldValues[3].value == 2) {
                initStockQuantity -= -val.quantity.reduce((a, b) => a - (-b), 0)
                if (!val.quantity[0] && !val.quantity[1]) {
                // eslint-disable-next-line no-param-reassign
                  val.quantityError = true
                  // eslint-disable-next-line no-param-reassign
                  val.quantityErrorMessage = 'The quantity field is required'
                }
              } else {
                initStockQuantity -= -val.quantity[0]
              }
            } else {
            // eslint-disable-next-line no-param-reassign
              val.quantityError = true
              // eslint-disable-next-line no-param-reassign
              val.quantityErrorMessage = 'The quantity field is required'
            }
          })
          this.openInventoryRequestTns.filter(obj => obj.item === item.item && obj.store === item.store).forEach(i => {
            initStockQuantity -= -i.totalQuantity
          })
          if (initStockQuantity > storeFound.stock) {
            errorFound += 1
          }
        } else {
          errorFound += 1
        }
      })
      if (errorFound > 0) {
        return false
      }
      return true
    },
    showPreviewModal() {
      this.$refs.irForm.validate().then(success => {
        if (this.stockItems.length && this.catchQuantityError() && success) {
          this.$root.$emit('bv::show::modal', `modal-request-items${this.uniqueComponentID}`, '')
        }
      })
    },
    closeRequestPreviewModal() {
      this.$root.$emit('bv::hide::modal', `modal-request-items${this.uniqueComponentID}`, '')
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    splitStockItems() {
      const stockOutPreviewItems = []
      this.stockItems.forEach(stockItem => {
        if (stockItem.quantity[0]) {
          stockOutPreviewItems.push({
            item: stockItem.item,
            name: stockItem.name,
            image: stockItem.image,
            sku: stockItem.sku,
            store: stockItem.store,
            department: 'NMG',
            quantity: stockItem.quantity[0],
          })
        }

        if (stockItem.quantity[1]) {
          stockOutPreviewItems.push({
            item: stockItem.item,
            name: stockItem.name,
            image: stockItem.image,
            sku: stockItem.sku,
            store: stockItem.store,
            department: 'MPL',
            quantity: stockItem.quantity[1],
          })
        }
      })

      return stockOutPreviewItems
    },
    submitStockOutForm() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submitted',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
