<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <b-row
        v-if="!isMobile()"
        class="mb-2"
      >
        <b-col
          cols="12"
          md="6"
          class=""
        >
          <span class="weight-500 fs-15">Forms</span>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class=""
        >
          <span class="weight-500 fs-15">Status</span>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class=""
        >
          <span class="weight-500 fs-15">Signed PDF Copy</span>
        </b-col>
      </b-row>
      <div
        v-for="(form, key) in requestForms"
        :key="key"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="hover-td"
          >
            <div
              v-if="isMobile()"
            >
              <span class="weight-500 fs-15">Forms</span>
            </div>
            <div
              class=""
            >
              <span class="">{{ form.formName }}</span>
              <b-button
                v-b-tooltip.hover.top="'Edit form'"
                variant="flat-primary"
                class="ml-75 wf-action-button"
                style="float: none; margin-top: 0 !important;"
                :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequestId }, query: { form: form._id } }"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>

              <b-button
                v-if="form.status == 'Signed and Sent' && form.pdfs.length"
                v-b-tooltip.hover.top="'Download without password'"
                variant="flat-primary"
                class="p-0 wf-action-button"
                style="float: none; margin-top: 0 !important;"
                @click="downloadSRForm(form._id, form.pdfs[0].name)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                />
              </b-button>
              <b-button
                v-if="['To prepare', 'Signing', 'Ready to sign'].includes(form.status)"
                v-b-tooltip.hover.top="'Download PDF'"
                variant="flat-primary"
                class="p-0 wf-action-button"
                style="float: none; margin-top: 0 !important;"
                @click="downloadSRFormPDF(form._id, form.formName)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class=""
          >
            <div
              v-if="isMobile()"
              class="mt-75"
            >
              <span class="weight-500 fs-15">Status</span>
            </div>
            <b-badge
              :variant="`light-${resolveFormStatusVariant(form.status)}`"
              :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequestId }, query: { form: form._id } }"
              style="margin-top: 7px;  padding: 4px 9px; font-size: 14px; line-height: 18px; font-weight: 400;"
              :class="{ 'mt-50': isMobile() }"
            >
              {{ form.status }}
            </b-badge>
            <br>
            <span v-if="form.pdfSentDate">
              {{ dateFormatWithTime(form.pdfSentDate) }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="list-item"
          >
            <div
              v-if="isMobile()"
              class="mt-75"
            >
              <span class="weight-500 fs-15">Signed PDF Copy</span>
            </div>
            <div v-if="form.pdfs.length">
              <app-collapse
                v-if="form.pdfs.length > 1"
                class="mt-50 reverse-icon"
              >
                <app-collapse-item title="Urn Repository Form">
                  <template
                    #header
                  >
                    <b-row
                      class="full-width"
                    >
                      <!-- Content Left -->
                      <b-col
                        class="content-header-left"
                        cols="12"
                        md="12"
                      >
                        <div
                          class="attachment-item"
                        >
                          <b-img
                            left
                            height="40"
                            :src="require('@/assets/images/admin/doc_file.png')"
                            alt="Left image"
                          />
                          <strong>
                            <b-link
                              :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                              target="_blank"
                            >
                              {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                            </b-link>
                          </strong>

                          <b-badge
                            variant="warning"
                            class="ml-auto"
                          >
                            Latest
                          </b-badge>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                  <b-row
                    v-for="(pdf, index) in form.pdfs"
                    :key="index"
                    class="full-width"
                  >
                    <b-col
                      v-if="index > 0"
                      class="content-header-left mt-50"
                      cols="12"
                      md="12"
                    >
                      <div
                        class="attachment-item"
                      >
                        <b-img
                          left
                          height="40"
                          :src="require('@/assets/images/admin/doc_file.png')"
                          alt="Left image"
                        />
                        <strong>
                          <b-link
                            :href="pdf ? pdf.data : ''"
                            target="_blank"
                          >
                            {{ pdf ? pdf.name : '' }}
                          </b-link>
                        </strong>
                      </div>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
              <div
                v-else
                class="attachment-item mr-28"
              >
                <b-img
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <strong>
                  <b-link
                    :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                    target="_blank"
                  >
                    {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                  </b-link>
                </strong>
                <b-badge
                  variant="warning"
                  class="ml-auto"
                >
                  Latest
                </b-badge>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr
          v-if="key < (requestForms.length - 1)"
          class="my-50"
        >
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, VBTooltip, BButton, BImg, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BImg,
    BLink,

    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    formOptions: {
      type: [Array, null],
      default: null,
    },
    serviceRequestForms: {
      type: [Array, null],
      default: null,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    requestForms() {
      const formArray = []
      this.fieldValues[3].value.forEach(field => {
        const formObj = {
          _id: '',
          status: '',
          pdfSentDate: '',
          pdfs: [],
        }
        formObj.formName = this.formName(field)
        // eslint-disable-next-line eqeqeq
        const reqForm = this.serviceRequestForms.find(o => o.serviceRequestTask == this.task._id && o.formID == field && o.taskBlockID == this.taskBlock)
        if (reqForm) {
          formObj._id = reqForm._id
          formObj.status = reqForm.status
          formObj.pdfSentDate = reqForm.pdfSentDate
          formObj.pdfs = reqForm.pdfs.reverse()
        }

        formArray.push(formObj)
      })
      return formArray
    },
  },
  created() {
    this.$emit('updateCount')
  },
  methods: {
    downloadSRForm(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${id}/detail`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.click()

          link.remove()
        })
    },
    downloadSRFormPDF(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${id}/detail`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${name.replace(/[/ ]/gi, '-')}.pdf`
          link.click()

          link.remove()
        })
    },
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        let fName = form.name
        if (form.formNumber && form.formNumber !== '-') {
          fName += ` (${form.formNumber})`
        }
        if (form.revision && form.revision !== '-') {
          fName += ` (${form.revision})`
        }
        return fName
      }

      return ''
    },
    formNameOnly(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        return form.name
      }

      return ''
    },
    resolveFormStatusVariant(status) {
      if (status === 'Signed and Sent') return 'success'
      if (status === 'Not required') return 'secondary'
      if (status === 'To prepare') return 'primary'
      return 'warning'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
}
th.no-bg {
  background: transparent !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.list-item .card .card-header.collapsed {
  padding: 0 !important;
}
.list-item .card .card-header {
  border-bottom: none !important
}
.list-item .attachment-item {
  background: rgba(16, 77, 157, 0.12);
  padding: 10px;
  border-radius: 5px;
}
.list-item .full-width {
  width: 100% !important;
}
.list-item {
  .collapse-icon [data-toggle=collapse]:after {
    top: 50% !important;
  }
}
.list-item .collapse-icon [data-toggle=collapse]:after {
  background-size: 20px !important;
}
</style>
